import {useEffect, useState} from "react";
import axios from "axios";
import {Card, Col, Row, Image} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";
import dayjs from "dayjs";

const OrduYerelGazeteMansetComponent = () => {
    const [data,setData] = useState([]);

    useEffect(() => {
        axios.get("https://ileti.orbel.com.tr/gunluk-gazete/").then(res => {
            setData(res.data)
            console.log(res.data)
        })
    }, []);



    return(
        <Card size={"small"} title={"ORDU YEREL GAZETE MANŞETLERİ"}>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={6}
                        autoplay={true}
                        modules={[Autoplay]}
                    >
                        {data && data.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Card size={"small"} style={{height:300, textAlign:"center"}}>
                                    <Image src={"https://ileti.orbel.com.tr/gunluk-gazete/image-to-byte.php?image_url="+item.fullSizeUrl+"&day="+dayjs(item.date).format("DD-MM-YYYY")}
                                           alt={item.title}
                                           style={{borderRadius:5, width:"100%", height:250, textAlign:"center", objectFit:"cover"}}></Image>
                                    <p style={{textAlign:"center", fontSize:"12px", fontWeight:"bold"}}>{item.caption}</p>
                                </Card>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Col>
            </Row>
        </Card>
    )
}

export default OrduYerelGazeteMansetComponent