import {createBrowserRouter, createRoutesFromElements, defer, Route} from "react-router-dom";
import AuthLayout from "./layout/AuthLayout";
import ProtectedLayout from "./layout/ProtectedLayout";
import DashboardPage from "./pages/home/DashboardPage";
import "../src/styles/Style.sass"
import 'swiper/css';
import 'swiper/css/pagination';
const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.localStorage.getItem("user");
            resolve(user);
        }, 1000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >

            <Route path="/" element={<ProtectedLayout />}>
                <Route path="/" element={<DashboardPage />} />
            </Route>

            <Route path="/dashboard" element={<ProtectedLayout />}>
                <Route path="/dashboard" element={<DashboardPage />} />
            </Route>

        </Route>
    )
)