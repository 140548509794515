import {useNavigate,} from "react-router-dom";
import {Button, Dropdown, Space} from "antd";
import {
    DownOutlined,
    HomeOutlined,
    LogoutOutlined, MenuOutlined,
    SettingOutlined,
    UserOutlined
} from "@ant-design/icons";


const HeaderLayout = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate()

    const items = [
        {
            label: 'Çıkış Yapın',
            key: '2',
            icon: <LogoutOutlined />,
        },
    ];

    const ayarlar = [

        {
            label: 'Kullanıcılar',
            key: '2',
            icon: <MenuOutlined />,
            onClick: () => navigate("/users")
        },
        {
            label: 'Paremetreler',
            key: '3',
            icon: <MenuOutlined />,
            onClick: () => navigate("/parametre")
        },
        {
            label: 'Sync Log',
            key: '4',
            icon: <MenuOutlined />,
            onClick: () => navigate("/sync")
        }
    ];


    return (
        <div className={"header"}>
            <div onClick={() => navigate("/dashboard")} className={"logo"}>
                <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'}
                     alt={"Firma Logosu"}></img>
                <div className={"logo-text"}>| Medya Takip</div>
            </div>
            <div className={"right"}>
                <Space>
                    <Button onClick={() => navigate("/dashboard")}>
                        <Space>
                            <HomeOutlined/> Anasayfa
                        </Space>
                    </Button>

                    {/*<Dropdown menu={{items:ayarlar}}>*/}
                    {/*    <Button >*/}
                    {/*        <Space>*/}
                    {/*            <SettingOutlined/> Ayarlar*/}
                    {/*            <DownOutlined />*/}
                    {/*        </Space>*/}
                    {/*    </Button>*/}
                    {/*</Dropdown>*/}

                    {/*<Dropdown menu={{items}}>*/}
                    {/*    <Button >*/}
                    {/*        <Space>*/}
                    {/*            <UserOutlined/>{user?.firstName} {user?.lastName}*/}
                    {/*            <DownOutlined />*/}
                    {/*        </Space>*/}
                    {/*    </Button>*/}
                    {/*</Dropdown>*/}
                </Space>
            </div>
        </div>
    )
}

export default HeaderLayout;