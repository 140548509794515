import {Await, useLoaderData, useOutlet} from "react-router-dom";
import {Suspense} from "react";
import {Alert} from "antd";
import {LayoutContextProvider} from "../context/LayoutContext";
import {AuthContextProvider} from "../context/AuthContext";


const AuthLayout = () => {
    const outlet = useOutlet();
    const { userPromise } = useLoaderData();

    return(<Suspense>
        <Await
            resolve={userPromise}
            errorElement={<Alert severity="error">Something went wrong!</Alert>}
            children={(user) => (
                <LayoutContextProvider>
                    <AuthContextProvider userData={user}>
                        {outlet}
                    </AuthContextProvider>
                </LayoutContextProvider>
            )}
        />
    </Suspense>)
}

export default AuthLayout
