import {createContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import TokenService from "../services/TokenService";

const AuthContext = createContext();

const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    const login = (user) =>{
        setUser(user);
        navigate("/dashboard", {replace: true});
        TokenService.setUser(user)
    }

    return(
        <AuthContext.Provider
            value={{
                user,
                login,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContextProvider, AuthContext}
