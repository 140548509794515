import {useEffect, useState} from "react";
import axios from "axios";
import {Card, Col, Row} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";

import {Autoplay} from "swiper/modules";
const OrduOlayComponent = ({drawerOpen}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios.get("https://ileti.orbel.com.tr/rss-to-json/?rss_url=https://www.orduolay.com/rss.xml").then(res => {
            setData(res.data.channel?.item)
        })
    }, []);



    return(
        <Card size={"small"} title={<img width={125}
                                         src={"https://static.daktilo.com/sites/1369/uploads/2023/04/11/large/desktop.png"}
                                         alt={"Ordu Olay"}></img>}>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <Swiper
                        spaceBetween={10}
                       slidesPerView={6}
                       autoplay={true}
                       modules={[Autoplay]}
                   >
                       {data && data.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Card size={"small"} style={{height:300}} onClick={() => drawerOpen(item.link)}>
                                    {item && item.enclosure && item.enclosure["@attributes"] && item.enclosure["@attributes"].url &&
                                        <img src={item?.enclosure["@attributes"]?.url} alt={item?.title} style={{borderRadius:5, width:"100%", height:225, objectFit:"cover"}}></img>
                                    }
                                    <p style={{textAlign:"center", fontSize:"12px", fontWeight:"bold"}}>{item.title}</p>
                                </Card>
                            </SwiperSlide>
                       ))}
                   </Swiper>
               </Col>
           </Row>
        </Card>
    )

}

export default OrduOlayComponent