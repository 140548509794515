import React from 'react';
import ReactDOM from 'react-dom';
import PreviewBrowser from 'embedded-react-browser';
const IframeDetailPage = ({url}) => {
 //iframe sayfa açsın
    return (
        <PreviewBrowser  url={url} theme="light" />
    )
}

export default IframeDetailPage