import OrduOlayComponent from "./partials/OrduOlayComponent";
import {Col, Drawer, Row} from "antd";
import OrduYorumComponent from "./partials/OrduYorumComponent";
import OrduHursesComponent from "./partials/OrduHursesComponent";
import OrduHayatComponent from "./partials/OrduHayatComponent";
import OrduGazeteComponent from "./partials/OrduGazeteComponent";
import OrduYeniDonemComponent from "./partials/OrduYeniDonemComponent";
import OrducuComponent from "./partials/OrducuComponent";
import OrduHaberAjansiComponent from "./partials/OrduHaberAjansiComponent";
import OrduAfisGazetesiComponent from "./partials/OrduAfisGazetesiComponent";
import OrduYerelGazeteMansetComponent from "./partials/OrduYerelGazeteMansetComponent";
import {useState} from "react";
import IframeDetailPage from "./IframeDetailPage";

const DashboardPage = () => {
    const [drawer, setDrawer] = useState({isOpen:false, url:""})

    const drawerOpen = (url) => {
        //blank ile link aç
        const width = 1200;
        const height = 1000;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);



    }

    return(<div className={"p-1"}>

        <Drawer
            open={drawer.isOpen}
            placement={"top"}
            destroyOnClose={true}
            className={"drawer-panding-none drawer-custom-background"}
            title={"ORBEL MEDYA TAKİP"}
            onClose={()=>{setDrawer({isOpen:false, type:"", title:""})}}
            height={"100%"}
        >
            <IframeDetailPage url={drawer.url}></IframeDetailPage>
        </Drawer>

        <Row gutter={[10,10]}>
            <Col span={24}>
                <OrduYerelGazeteMansetComponent></OrduYerelGazeteMansetComponent>
            </Col>
            <Col span={24}>
                <OrduYorumComponent drawerOpen={(url) => drawerOpen(url)}></OrduYorumComponent>
            </Col>
            <Col span={24}>
                <OrduHursesComponent drawerOpen={(url) => drawerOpen(url)}></OrduHursesComponent>
            </Col>
            <Col span={24}>
                <OrduHayatComponent drawerOpen={(url) => drawerOpen(url)}></OrduHayatComponent>
            </Col>

            <Col span={24}>
                <OrduGazeteComponent drawerOpen={(url) => drawerOpen(url)}></OrduGazeteComponent>
            </Col>

            <Col span={24}>
                <OrduYeniDonemComponent drawerOpen={(url) => drawerOpen(url)}></OrduYeniDonemComponent>
            </Col>

            <Col span={24}>
                <OrduHaberAjansiComponent drawerOpen={(url) => drawerOpen(url)}></OrduHaberAjansiComponent>
            </Col>

            <Col span={24}>
                <OrduAfisGazetesiComponent drawerOpen={(url) => drawerOpen(url)}></OrduAfisGazetesiComponent>
            </Col>

            <Col span={24}>
                <OrducuComponent drawerOpen={(url) => drawerOpen(url)}></OrducuComponent>
            </Col>

            <Col span={24}>
                <OrduOlayComponent drawerOpen={(url) => drawerOpen(url)}></OrduOlayComponent>
            </Col>
        </Row>
    </div>)
}

export default DashboardPage