import {createContext, useState} from "react";

const LayoutContext = createContext();

const LayoutContextProvider = ({children}) => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
    return(
        <LayoutContext.Provider
            value={{
                sidebarIsOpen,
                setSidebarIsOpen,
            }}
        >
            {children}
        </LayoutContext.Provider>
    )
}
export {LayoutContextProvider, LayoutContext}
